@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}

.webpack-dev-server-client-overlay {
  display: none;
}

.mobile::-webkit-scrollbar, .mobile *::-webkit-scrollbar {
  width: 10px !important;
}

.mobile::-webkit-scrollbar-track,
.mobile *::-webkit-scrollbar-track {
  background-color: transparent !important;
  box-shadow: transparent !important;
}

.mobile::-webkit-scrollbar-thumb, .mobile *::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #2d2b2d;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background-color: #c0d4de;
  min-height: 100vh;
}

body.scroll-lock {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  touch-action: none;
  -webkit-overflow-scrolling: none;
}

h1 {
  font-size: 1.5em;
  word-wrap: break-word;
}

h2 {
  font-size: 1.25em;
  word-wrap: break-word;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.innerHTMLrules p, .innerHTMLrules ul {
  margin: 0
}

.textNoWrap {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scoremagicLineHeight {
  line-height: 1em;
  padding: 3px 0;
}

@media screen and (max-width: 900px) {
.scoremagicLineHeight {
    line-height: .4em;
    padding: 0
  }
}

.scrollLock {
  overflow-y: hidden
}

.showModal {
  bottom: 0 !important;
}

 .showModalWeb {
  bottom: 50% !important;
  transform: translateY(50%);
  /* top: 0; */
  left:0;
  /* bottom: 0; */
  right: 0;
  border-radius: 20px!important;
  max-height: 99vh;
  /* -ms-overflow-style: none; */
    /* Internet Explorer 10+ */
    /* scrollbar-width: none; */
    /* Firefox */
    /* height: 100%!important */
}

.showModalWeb::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
  /* background-color: red !important; */
    border: 1px solid transparent !important;
    /* border-radius: 8px !important; */
    /* background-clip: padding-box !important; */
    width: 7px;
}

.showModalWeb::-webkit-scrollbar-thumb {
  background-color: #cbcbcb;
  border: 2px solid transparent !important;
  /* width: 10px; */
  border-radius: 20px!important;
  background-clip: padding-box!important;
}

.showOverlay {
  top: 0 !important;
  display: block !important;
}

.descriptionHeight {
  max-height: 50px;
  /* transition: .3s */
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.descriptionHeight.fullDescriptionHeight {
  max-height: 700px;
  transition: max-height 1s ease-in-out;
}

.eventListItem {
  transition: .3s;
  border-radius: 10px;
  padding: 5px;
  margin: 0 3px;
}
.eventListItem:hover {
  border-radius: 20px;
  margin: 0 10px;
  /* border-width: 2px!important */
}

.restrictionsAccordion .MuiAccordionDetails-root {
  padding: 10px 5px 10px;
  }
  
  .restrictionsAccordion {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .rosterPlayerRow.restrictionsAccordion .MuiAccordionDetails-root {
    padding: 5px 1px 5px;
    width: 100%;
  }
  
  .rosterPlayerRow.restrictionsAccordion .MuiAccordionSummary-root {
    padding: 0 4px;
  }
  
  .rosterPlayerRow.restrictionsAccordion .MuiAccordionSummary-content {
    margin: 0;
  }
  
  .rosterPlayerRow.restrictionsAccordion {
    margin-bottom: 5px;
}
.format-type-btns {
  margin-top: 10px
}

.format-type-btns .MuiToggleButtonGroup-grouped {
  flex: 1 1 33.3% !important;
  padding: 5px;
}

.format-type-btns .MuiButtonBase-root.MuiToggleButtonGroup-grouped span,
.format-type-btns .MuiButtonBase-root.MuiToggleButtonGroup-grouped.Mui-selected span {
  font-size: .7em !important
}
pre {
  font-family: 'Courier New', Courier, monospace;
}

.eventButton{
  border-radius: 10px
}
.eventButton:hover {
  border-radius: 40px
}

.largeButtonHover {
  transition:.3s
}
.largeButtonHover:hover {
  box-shadow: 0 6px 10px #e28e8e
}

.blurComponent {
  filter: blur(5px);
}

.blurComponentBackdrop {
  backdrop-filter: blur(5px);
}

.inputField fieldset {
  border-radius: 40px;
  box-shadow: 0px 4px 8px #9a9a9a35;
}

.restrictionInputSmall {
  max-width: 100px;
}
.inputFieldNoShadow {
  box-shadow:none!important
}

.test {
  border: 2px solid black;
}

.adnimateFadeIn {
  animation: fadein 2s;
}

.animate {
  transition: transform 0.35s ease-in-out;
}
.animateFast {
  transition: transform 0.1s ease-in-out;
}

.animate__animated.animate__slideInLeft,
.animate__animated.animate__slideInRight {
  --animate-duration: .2s;
}

.animation-enter {
  opacity: 0;
  transform: scale(0.9);
}
.animation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.animation-exit {
  opacity: 1;
}
.animation-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.App {
  width: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button.hidden {
  display: none;
}
span.hidden {
  display: none;
}

.fadeOut {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.ag-theme-material .ag-cell,
.ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 4px !important;
  padding-right: 4px !important;
  line-height: 1.6em;
}
.ag-theme-material .ag-root-wrapper {
  border-radius: 20px;
  background-color: transparent !important;
  padding: 5px;
}
.ag-theme-material .ag-paging-panel {
  flex-direction: column;
}
.ag-theme-material .ag-theme-material .ag-paging-panel > * {
  margin: 4px 24px !important;
}
.ag-theme-material .ag-header {
  background-color: transparent !important;
  height: 46px !important;
  min-height: 46px !important;
}
.ag-theme-material .ag-cell,
.ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 1.6em !important;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 4px !important;
  padding-right: 4px !important;
  line-height: 1.6em;
}
.ag-theme-alpine .ag-root-wrapper {
  border-radius: 20px;
  background-color: transparent !important;
  padding: 5px;
}
.ag-theme-alpine .ag-paging-panel {
  flex-direction: column;
}
.ag-theme-alpine .ag-theme-alpine .ag-paging-panel > * {
  margin: 4px 24px !important;
}
.ag-theme-alpine .ag-header {
  background-color: transparent !important;
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 1.6em !important;
}
.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}
.ag-theme-alpine .ag-paging-panel {
  margin-bottom: 10px;
}
input.ag-input-field-input {
  font-size: 16px !important;
}
.ag-theme-alpine .ag-row {
  font-size: 12px !important;
}
.ag-theme-alpine {
  font-size: 12px !important;
}
.ag-theme-alpine .ag-root-wrapper {
  padding: 5px !important;
}
.ag-cell-wrap-text {
  word-break: break-word !important;
}
.ag-theme-alpine .strikethrough .ag-cell-wrapper {
  text-decoration: line-through !important;
  color: #b4b4b4 !important;
}
.ag-theme-alpine .noBorder [class^=ag-],
.ag-theme-alpine .noBorder [class^=ag-]:focus,
.ag-theme-alpine .noBorder [class^=ag-]:after,
.ag-theme-alpine .noBorder [class^=ag-]:before {
  border: none !important;
  background-color: transparent!important;
}
.ag-row.ag-row-level-0.ag-full-width-row {
  /* min-height: 90!important; */
  height: auto!important
  /* transform:none!important */
}
.ag-theme-alpine .ag-row-even>.ag-cell {
  background: #ecedf1;
  padding: 3px 0 5px;
  /* background: #b1adff1c; */
}
.ag-ltr .ag-header-cell-resize {
  display: none
}
.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

.countdownSlider {
  width: 100%;
  height: 10px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
}

.countdownSliderFill {
  height: 100%;
  background-color: #3333B7;
  transition: width 0.1s linear;
}
.setupDashGridWrapper {
  height: auto;
  width: 100%;
.ag-root-wrapper {
    height: 100% !important;
  }
    @media screen and (min-width: 600px) {
  
      .ag-header,
        .ag-paging-panel {
          display: none !important;
          height: 0 !important;
          overflow: hidden !important;
        }
        .ag-cell {
          margin: 0 !important;
          display: flex !important;
          align-items: center;
          padding: 0 !important;
          border-width: 1px !important;
          box-sizing: border-box !important;
        }
        }
.ag-cell-wrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-body-horizontal-scroll {
display: none;
}
span.ag-group-value {
  font-size: 1.5em;
  color: #fff;
}

span.ag-cell-wrapper.ag-cell-expandable.ag-row-group.ag-row-group-indent-0 {
  background-color: #42eb0f45;
}

// .ag-full-width-container {
//   border: 1px solid #00800047;
//   /* border-radius: 10px; */
// }
}
.progress-bar {
  position: relative;
    display: flex;
    height: 20px;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 50px;
  overflow: hidden;
}

.filler {
  position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
  background-color: #6658dd;
  transition: width 0.2s ease-in;
}
.GameplayStandingsViewGridWrapper .ag-ltr .ag-header-cell-resize {
  right: -4px;
  display: none;
}

.GameplayStandingsViewGridWrapper.bagStats .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell),
.GameplayStandingsViewGridWrapper.bagStats .ag-pinned-left-header {
  border-right: 1px solid rgb(120, 120, 120) !important;
}

.GameplayStandingsViewGridWrapper.bagStats .ag-theme-alpine .ag-details-row {
  padding: 1px 1px 2px 1px;
  background: goldenrod;
}

.GameplayStandingsViewGridWrapper.bagStats .ag-theme-alpine .ag-details-row .ag-root::after {
  content: none;
  position: absolute;
  width: 100%;
  height: 1px;
  background: goldenrod;
  z-index: 50;
}
.gameplayDashGridWrapper {
  height: auto;
  width: 100%;

  .ag-root-wrapper {
    height: 100% !important;
  }

  @media screen and (min-width: 767px) {

    // .ag-header,
      // .ag-paging-panel {
      //   display: none !important;
      //   height: 0 !important;
      //   overflow: hidden !important;
      // }

    .ag-cell {
      margin: 0 !important;
      display: flex !important;
      align-items: center;
      padding: 0 !important;
      border-width: 1px !important;
      box-sizing: border-box !important;
    }
  }

  .ag-cell-wrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  span.ag-group-value {
    font-size: 1.5em;
    color: #fff;
  }
    span.ag-group-value {
      font-size: 1.5em;
      color: #fff;
    }

  span.ag-cell-wrapper.ag-cell-expandable.ag-row-group.ag-row-group-indent-0 {
    background-color: #42eb0f45;
  }

  // .ag-full-width-container {
  //   border: 1px solid #00800047;
  //   /* border-radius: 10px; */
  // }
}

 .hidePagination .ag-paging-panel {
   display: none !important;
   height: 0 !important;
   overflow: hidden !important;
 }
@media screen and (min-width: 767px) {
  .gameplayDashGridWrapper {
    overflow: hidden;
  }
}
@keyframes grow-shrink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.93);
  }

  100% {
    transform: scale(1);
  }
}

.gameplayNewCourt {
  animation: grow-shrink 0.6s ease-in-out;
}
 @media screen and (min-width: 600px) {
   .setupDashGridWrapper {
     height: calc(100vh - 300px);
   }
 }

.setupDashGrid .ag-root-wrapper {
  height: 100%;
  width: 100%;
}
.setupDashGrid .ag-ltr .ag-header-cell-resize {
  display: none;
}

// .setupDashGrid .ag-header-cell-menu-button {
//   opacity: 1 !important;
// }

.setupDashDetailCell,
.setupDashDetailCell .ag-header-cell-label {
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  overflow: visible !important
}

.gameplayDashDetailCell,
.gameplayDashDetailCell .ag-header-cell-label {
  // border: none !important;
  display: flex !important;
  justify-content: center !important;
  overflow: visible !important;
  padding: 0 2px !important;
}

.gameplayDashDetailCell:not(.gameplayDashTeamCell) .ag-cell-value {
  text-align: center !important;
}

.gameplayDashDetailCell .ag-header-cell-menu-button {
  display: none;
}

.bagStats .gameplayDashTeamCell .ag-cell-wrapper {
  padding-left: 3px !important;
}

.GameplayStandingsViewGridWrapper.bagStats .ag-row {
  border-bottom: 1px solid #ffffff0d !important;
}
.GameplayStandingsViewGridWrapper .ag-paging-panel {
  display: none !important;
  height: 0 !important;
  overflow: hidden !important;
}
.noBorderCell,
.setupDashGrid .ag-header.ag-pivot-off,
.setupDashGrid .ag-paging-panel {
  border: none !important;
}

.bagStats .ag-cell-wrapper {
  max-width: 100%;
    width: 100%;
}
.headerHeightTall .ag-header-row.ag-header-row-column,
.headerHeightTall .ag-header.ag-pivot-off {
  height: 40px !important
}
.fullWidthCustom .ag-full-width-container {
  width: 100% !important;
}
.noBorder .ag-theme-alpine .ag-row-selected,
.noBorder .ag-theme-alpine .ag-row-hover
  {
  background-color: none!important;
}
.noBGColor {
  background-color: transparent!important;
  border-radius: 10px;
}
.noBGColor.ag-cell-focus, .ag-cell-focus {
  background-color: transparent !important;
  border: none!important;
  outline: none!important;
  border-radius:  10px;
  /* padding-top: 4px; */
  transition: .3s
}

/* Org Stats Grid */
.ag-ltr .ag-side-bar-right .ag-tool-panel-wrapper {
  /* border-right: none !important; */
  width: auto !important;
}
.react-contexify {
  border: 1px solid #CC0C0D;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.react-contexify__item {
  text-align: center
}

.filterSection {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  padding: 0 15px;
  /* width: 100%; */
}

.showFilterSection {
  max-height: 500px;
}

.filterPill {
  padding: 0px 4px;
  border: 1px solid #CC0C0D;
  border-radius: 30px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    margin-right: 5px;
}

input.select-dropdown {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

div.MuiPaper-root {
  transition: none !important;
}


.quickToggleSelector {
  transition: .3s;
  z-index: -1;
  left: 5px;
  position:absolute;
      top: 3px;
      bottom: 3px;
      border-radius: 20px;
      width: 50%;
}

.quickToggleSelected {
  left:calc(50% - 5px);
}

.MuiInputLabel-outlined.selectInputSmall{
  font-size: .85em;
  padding-top: 2px;
  font-weight: 500;
  color: #2D2B2D
}

.MuiInputLabel-outlined.selectNoBorderRight { 
  border: none
}

.trophyStats .MuiSelect-select.MuiOutlinedInput-input {
  border-radius: 20px;
}
#tournamentInputLabel.MuiSelect-select,
#tournamentLabelSelect, input#outlined-basic-small, .inputFieldSmall.inputFieldSmall .MuiInputLabel-root, .standAloneLabel {
  /* font-size: .8em; */
  font-weight: 500;
  color: #2D2B2D;
}
.inputFieldDate {
  margin: 10px 0;
}
.inputFieldDate>div {
  width: 100%;
}
.inputFieldDate input {
  padding: 9px 15px;
  width: 100%;
}
.inputFieldDate label:not(.MuiInputLabel-shrink) {
  line-height: .4375em;
  overflow: visible;
}

.selectInputSmall.border10  {
  border-radius: 10px!important;
}
.selectInputSmall.border20,  
.selectInputSmall.border20 fieldset  {
  border-radius: 20px;
}

.wysiwygEditorWrapper {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 10px;
  border-radius: 20px;
}

.clubAboutEditor .rdw-editor-main {
    min-height: 100px;
    border-radius: 11px;
    padding: 0 10px;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.CUSTOM-ICON {
  color: rgba(0, 0, 0, 0.54);
    font-size: 25px;
    height: 38px;
    margin-right: 10px;
    opacity: .55;
    transition: fill .2s cubic-bezier(.4, 0, .2, 1) 0ms;
    width: 40px;
}

.calculatorInput {
  width: 25%;
}

@media screen and (max-width: 350px) { 
  .calculatorInput {
      width: 50%;
    }
} 

.adSlider {
  max-height: 150px;
}

@media screen and (min-width: 900px) {
  .adSliderHideLarge {
    display: none !important
  }
}
@media screen and (max-height: 800px),
screen and (max-width: 700px) {
  .adSlider {
    max-height: 100px;
  }

  .wantedListBanner img {
    max-width: 220px !important;
  }

  .wantedBannerHeading,
  .wantedContentHeading {
    font-size: 1.4em !important;
  }

.wantedBannerText,
.wantedTableHeading span,
.wantedTableRow span {
  font-size: 1em !important;
}

.wantedTableContainer {
  max-height: calc(100vh - 270px) !important;
}

.wantedListContentSection {
  padding: 5px !important
}
}

@media screen and (max-width: 900px) {
  .adSlider {
    max-height: 75px !important;
  }

  .adSliderHideSmall {
    display: none !important
  }
}
@media screen and (max-width: 600px) {
  .dashHeaderFlexRow {
    flex-direction: column !important;
  }

  .adSliderHideLarge {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0 !important;
  }
}

.upgrade-bg {
  background: linear-gradient(-203deg, #E08185 0,#9F93CF 74%, #8989D5 100%)
}

.upgrade-bg .MuiMenu-list {
  height: 225px;
}

// .playerRecap.upgrade-bg .MuiMenu-list {
//   height: auto;
// }

// .playerRecap .MuiInputBase-root.MuiOutlinedInput-root {
//   // margin-bottom: 0;
//   max-width: 150px;
//   margin: 0 auto;
//   border-radius: 5px;
// }

//This is the year dropdown selection
.yearEndRecap .MuiInputBase-root.MuiOutlinedInput-root {
    width: 80px;
}
.yearEndRecap .MuiInputBase-root.MuiOutlinedInput-root .MuiSelect-select.MuiSelect-outlined {
    padding: 7px 10px;
}



.carousel-root {
  height: 340px
}

.carousel.carousel-slider {
  overflow: visible!important
}

.carousel .control-dots {
  bottom: -35px!important
}

/* .ag-theme-alpine .strikethrough.ag-row-level-0 {
  text-decoration: line-through;
  background-color: #b4b4b4;
} */

/* .ag-theme-alpine .ag-row-selected {
  background-color: transparent!important;
} */

.findTournamentGrid .ag-body-viewport.ag-row-no-animation.ag-layout-auto-height {
  width: 100% !important;
}

.animateDiv {
  transition: .3s;
  max-height: 5000px;
}

.tournamentFormAccordion {
  max-height: 0px;
  overflow: hidden;
  transition: .2s;
}

.tournamentFormAccordion.open {
  max-height: 1000px
}

.statTableRow {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.statTableRow.open {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


#map {
  height: 600px;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  color: #333;
}

.landmark {
  padding: 7px;
  background: #fff;
  border: 1px solid #949CC160;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  transform-origin: 0 10px;
  display: flex;
  flex-direction: column;
  font-size: .8em;
  color: #333;
}

@media screen and (max-width: 600px) {
  .landmark {
    width: 80vw;
    max-width: 1000px
  }
}

@media screen and (min-width: 601px) {
  .landmark {
    max-width: 440px;
}
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

.landmarkRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.noFlex {
  flex: 0
}

.alignFlexEnd {
  align-items: flex-end
}

.alignCenter {
  align-items: center;
}
.alignFlexEnd {
  align-items: flex-end;
}
.justifyCenter {
  justify-content: center;
}
.justifyFlexEnd {
  justify-content: flex-end;
}

.teamCreate {
  font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 1px 0px rgba(0, 0, 0, .1)
}

.gameTime {
padding-right: 5px;
  white-space: normal;
  text-align: right
}
.landmarkName {
  font-size: 1.2em;
  margin-top: 10px;
  font-weight: 700;
}
.landmarkLocationName {
    font-style: italic;
    padding-bottom: 2px;
}
.landmarkButton {
  background: #CC0C0D;
  border: 1px solid #CC0C0D;
  padding: 8px 13px;
  border-radius: 30px;
  /* align-self: center; */
  margin-top: 10px
}

.landmarkOrgName {
  padding: 2px 0;
    font-weight: 800;

}

.landmarkButtonText {
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
}
/* .landmark:after {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 52%;
  width: 0;
  height: 0;
  transform: rotate(-90deg) translate(-50%, -50%);
  margin-bottom: -13px;
  border-right: 13px solid #fff;
  border-top: 13px solid rgba(0, 0, 0, 0);
  border-bottom: 13px solid rgba(0, 0, 0, 0);
  /* z-index: 100; */
/*} 
*/

.trophyRoomBtn {
  display: flex;
  outline: none;
  font-family: inherit;
  box-sizing: border-box;
  border: none;
  border-radius: 20px;
  /* height: 2.75em; */
  line-height: 1em;
  text-transform: uppercase;
  padding: 5px 1em;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4), inset 0 -2px 5px 1px rgb(139, 66, 8), inset 0 -1px 1px 3px rgb(250, 227, 133); */
  background-image: linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07);
  /* border: 1px solid #a55d07; */
  color: rgb(120, 50, 5);
  // text-shadow: 0 2px 2px rgb(0 0 0);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-size: 100% 100%;
  background-position: center;
  align-items: center
}

.trophyIcon {
  path {
    stroke: black !important;
    stroke-width: 1.2px !important;
  }
}

.trophyTitle {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0) skew(-30deg);
  padding: 0 10px;
  background: #fff;
  border: 1px solid gold;
  border-radius: 3px;
}

.trophyTitleInvisible {
  position: absolute;
  top: 30%;
  left: 50%;
  padding: 2px 10px;
  transform: translate(-50%, 0);
}
.shareQRCode {
  display: none
}
@-webkit-keyframes scale-and-fadein {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-and-fadein {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/* LightMode */
:root {
  --backgroundColor: #C0D4DE;
  --contentBackgroundColor: #F7F7F7;
  --modalBackgroundColor: #fff;
  --contentDropShadow: #9a9a9a25;
  --mainFontColor: #2D2B2D;
  --mainFontColor20: #2D2B2D20;
  --mainFontColorLight: #2b2c3390;
}

/* .mainFontColor {
  color: var(--mainFontColor)
} */

.mainFontColorQuickToggle {
  color: #CC0C0D
}

.mainFontColorLight {
  color: var(--mainFontColorLight)
}

.contentBackgroundColor {
  background-color: var(--contentBackgroundColor);
}

.globalBackground {
  background-color: var(--backgroundColor);
}

.flexModalBackground {
  background-color: #efefef;
}

.NavbarLi {
  background-color: var(--contentBackgroundColor);
  border-right: 1px solid var(--contentBackgroundColor);
}

.ContentSection {
  background-color: var(--contentBackgroundColor);
  filter: drop-shadow(0px 6px 13px var(--contentDropShadow));
}

.recapDark .ContentSection {
  background-color: var(--contentBackgroundColor);
  filter: drop-shadow(0px 4px 4px var(--contentDropShadow));
}
.SlideUpModal,
.modalBackground {
  background-color: var(--modalBackgroundColor);
}

.LargeButtonSecondary {
  background-color: var(--modalBackgroundColor)
}

nav ul li>a {
  /* background-color: var(--backgroundColor); */
  color: var(--mainFontColor)
}
svg {
  color: var(--mainFontColor)
}
/* DarkMode Updates */
body.dark {
  color: var(--mainFontColor);
  background-color: #131A1F
}

.Text,
.autoCompleteInput,
.MuiInputLabel-outlined.selectInputSmall,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--mainFontColor)
}

.robotoFont {
  font-family: "Roboto", sans-serif;
}
body {
  color: var(--mainFontColor)
}

.toggleSwitchBorder {
  border: 1px solid var(--mainFontColor20);
}

.practiceDetailBG {
  background-color: #efefef;
}

.detailRowBG {
  background-color: none;
}

.detailRowBGLight {
  background-color: #fff;
}


nav ul li>a.forceActive {
  color: #fff
}
nav ul li>a.disabled {
  color: gray;
  background-color: darkgray;
}

.dark {
/* Darkmode */
  --backgroundColor: #131A1F;
  --contentBackgroundColor: #1F2A34;
  --modalBackgroundColor: #131A1F;
  --contentDropShadow: #14151C;
  --mainFontColor: #EBF1F4;
  --mainFontColor20: #EBF1F420;
  --mainFontColorLight: #EBF1F490;
& nav ul li>a {
    background-color: #0d1116;
}
& nav ul li>a[active], nav ul li>a.forceActive {
  background-color: #CC0C0D;
}

/* Org Stats Grid */
.ag-ltr .ag-side-bar-right .ag-tool-panel-wrapper,
.ag-text-field-input,
.ag-group-title-bar.ag-filter-toolpanel-group-title-bar,
.ag-filter-body-wrapper.ag-simple-filter-body-wrapper,
.ag-select .ag-picker-field-wrapper,
.ag-popup {
  background-color: var(--backgroundColor);
}

.ag-column-drop-empty-message {
  color: var(--mainFontColor) !important;
}
.ag-overlay-loading-wrapper {
  background-color: var(--backgroundColor);
}
.mainFontColorQuickToggle,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root
{
  color: var(--mainFontColor)
}

.flexModalBackground {
  background-color: var(--modalBackgroundColor);
}
.ag-theme-alpine .ag-row {
  background: #0d111670;
  border: none
}
.practiceDetailBG {
  background-color: var(--contentBackgroundColor);
}

.detailRowBGLight {
  background-color: var(--backgroundColor);
}
.ag-theme-alpine .ag-row-even>.ag-cell,
.bagStats .ag-theme-alpine .ag-details-grid .ag-header-cell,
.bagStats .ag-theme-alpine .ag-row-even>.ag-cell,
.bagStats .ag-details-grid-auto-height.ag-theme-alpine.ag-details-grid {
  background: #0d1116eb;
}

.bagStats .ag-theme-alpine .ag-details-grid .ag-row-odd>.ag-cell {
  background: #0d1116;
}

.ag-header-cell:last-of-type .ag-header-cell-resize::after {
  display: none
}
.MuiPaper-root.MuiAccordion-root {
  background: #131A1F;
}

.react-select-container>div,
.pac-container {
  background-color: #1F2A34;
  border: 1px solid #EBF1F450;
}

.pac-container {
  border-radius: 10px
}

.react-select-container div[class$="menu"],
.MuiSelect-select,
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: #131A1F;
}

.statsDropdown .MuiSelect-select {
  background-color: transparent
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border: 1px solid #EBF1F450
}
.react-select-container div[class$="option"] {
  background-color: transparent;
}

.react-select-container>#react-select-2-listbox {
  border: 1px solid #EBF1F450;
  background: #131A1F;
}
.MuiPaper-root.MuiPaper-elevation>ul.MuiList-root {
  background: #131A1F;
  /* border: 1px solid #EBF1F450; */
  color: #fff;
}

[class^=ag-],
[class^=ag-]:focus,
[class^=ag-]:after,
[class^=ag-]:before {
  box-sizing: border-box;
  outline: none;
  color: #cbd2d5;
}

.MuiTab-root,
.Mui-selected,
#tournamentInputLabel.MuiSelect-select,
#tournamentLabelSelect,
input#outlined-basic-small,
.inputFieldSmall.inputFieldSmall .MuiInputLabel-root,
.standAloneLabel,
.MuiFormLabel-root.MuiInputLabel-root,
.MuiInputBase-input,
.MuiSvgIcon-root,
span.ag-header-cell-text,
body,
h1,
h2,
.wysiwygEditorWrapper,
.pac-item span,
.css-jm5n9m-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  color: #EBF1F4 !important
}

.MuiTabs-indicator {
    background-color: #EBF1F4 !important
  }
.MuiSwitch-track {
  background: #ffffff;
}

.MuiFormControl-root {
    box-shadow: none;
  }
  
 .inputField fieldset,
 .MuiPaper-root.MuiAccordion-root,
 fieldset.MuiOutlinedInput-notchedOutline,
 .wysiwygEditorWrapper,
 .rdw-editor-toolbar {
    border: 1px solid #EBF1F450;
    box-shadow: none;
    }
.eventListItem::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.rdw-editor-toolbar {
  background: transparent;
  border-radius: 10px;
}

.rdw-dropdown-selectedtext,
.rdw-fontsize-option,
div[id^="react-select-2-option"],
.css-tj5bde-Svg,
div [class$="-MenuList"]>div[class$="-option"] {
  color: #333
}

.MuiToggleButtonGroup-root button.MuiToggleButton-standard:not(.Mui-selected) {
  background: #EBF1F430
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.MuiSelect-select.Mui-disabled {
  -webkit-text-fill-color: var(--mainFontColor);
}
.showModalWeb::-webkit-scrollbar-thumb {
  background-color: #EBF1F430;
}
}